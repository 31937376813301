import { releaseTimeslot } from '@/api/Timeslots.js';
import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';
//import User from '@/store/user.js';

const INITIAL_SUBJECT_VALUE = {
  reservation: undefined,
  selectedDay: undefined,
  selectedTimeslot: undefined,
};
const LOCAL_STORAGE_KEY = 'prebook';

/*const releaseSelectedTimeslot = async (requestId, token) => {
  // attempt to release the BE timeslot. No need to display an error message here
  try {
    await releaseTimeslot(requestId, token);
  } catch (err) {
    console.log('>>>error releasing timeslot', err);
  }
};*/

const Prebook = {
  subject: new BehaviorSubject(null),

  // eslint-disable-next-line sort-keys
  _lazyInit() {
    if (Prebook.subject.value !== null) return Prebook.subject;
    if (typeof window !== 'undefined') {
      if (window?.localStorage) {
        const storage = window.localStorage;
        const prebook = jsonStorage.get(LOCAL_STORAGE_KEY, { storage });
        const newSubject = !!prebook ? new BehaviorSubject(prebook) : new BehaviorSubject(INITIAL_SUBJECT_VALUE);
        Prebook.subject = newSubject;
        return Prebook.subject;
      }
    }
  },

  getSubject() {
    return Prebook._lazyInit();
  },

  getValue() {
    const prebook = Prebook._lazyInit();
    return prebook?.value;
  },

  subscribe(setState) {
    const prebook = Prebook._lazyInit();
    return prebook.subscribe(setState);
  },

  reset() {
    const prebook$ = Prebook.getSubject();
    const prevState = prebook$.value;

    //include this when ready from BE
    /*    if (prebook$.value.reservation?.requestId)
      releaseSelectedTimeslot(
        prebook$.value.reservation?.requestId,
        User.getValue().token || User.getValue().authToken
      );*/

    Prebook.getSubject().next({ ...prevState, reservation: null });

    Prebook.updateLocalStorage();
  },

  update(newValues) {
    const prebook$ = Prebook.getSubject();
    const prevState = prebook$.value;

    const newState = {
      ...prevState,
      ...newValues,
    };
    Prebook.updateLocalStorage(newState);
    prebook$.next(newState);
  },

  updateLocalStorage(newState) {
    if (window?.localStorage) {
      const storage = window.localStorage;
      jsonStorage.set(LOCAL_STORAGE_KEY, newState, { storage });
    }
  },
};

export default Prebook;
