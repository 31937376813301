import { BehaviorSubject } from 'rxjs';

const subjectGenericTimeslotsFetched = new BehaviorSubject(false);

export const GenericTimeslotsFetched = {
  update: fetched => {
    subjectGenericTimeslotsFetched.next(fetched);
  },
};

export const GenericTimeslotsStore = {
  subject: new BehaviorSubject([]),
  update: timeslots => {
    GenericTimeslotsStore.subject.next(timeslots);
    GenericTimeslotsFetched.update(true);
  },
  subscribe: setState => GenericTimeslotsStore.subject.subscribe(setState),
  getValue: () => GenericTimeslotsStore.subject.value,
  isFetched: () => GenericTimeslotsStore.value,
  reset: () => GenericTimeslotsStore.update(false),
};

export default {
  GenericTimeslotsStore,
  GenericTimeslotsFetched,
};
