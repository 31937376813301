import { BehaviorSubject } from 'rxjs';

const INITIAL_SUBJECT_VALUE = {
  reservation: undefined,
  selectedDay: undefined,
  selectedTimeslot: undefined,
};

const subjectPrebook = new BehaviorSubject(INITIAL_SUBJECT_VALUE);

const PrebookEditOrder = {
  subscribe: setState => subjectPrebook.subscribe(setState),
  getValue: () => subjectPrebook.value,

  update(newValues) {
    const prevState = subjectPrebook.value;

    const newState = {
      ...prevState,
      ...newValues,
    };
    subjectPrebook.next(newState);
  },
  reset() {
    const prevState = subjectPrebook.value;

    subjectPrebook.next({ ...prevState, reservation: null });
  },
};

export default PrebookEditOrder;
