import { getV3DeliveryServicesConfiguration } from '@/api/configuration.js';
import { getDeliveryServiceIcon } from '@/components/Aside/ServiceAddressAside.jsx';
import DeliveryServicesStore from '@/store/deliveryServices.js';
import { PostalCode } from '@/store/isAddressRegistered.js';
import Prebook from '@/store/prebook.js';
import { DeliveryStore as DeliveryAddressIndexStore } from '@/store/sideSheet';
import { Shop } from '@/store/shop.js';
import { InitPickupMethodStore } from '@/store/sideSheet.js';
import User from '@/store/user.js';
import { fetchTimeslots } from '@/utils/expressMode.js';
import { GeneralSelectedTimeslotStore, FirstSelectedTimeslotStore } from '@/store/selectedTimeslot';
import { setShopId } from '@/utils/products/products';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { GenericTimeslotsStore } from '@/store/timeslots';
import { SelectedTakeAwayStoreIndex } from '@/store/selectedTakeAwayIndex';
import { dayjs, setDefaultTz } from '@/utils/dates/dates';

export default function useDeliveryServices() {
  const [deliveryServices, setDeliveryServices] = useState(DeliveryServicesStore.getValue() || []);
  const [shop, setShop] = useState(Shop.getValue() || []);
  const t = useTranslations('common');
  const [selectedUserAddressIndex, setSelectedUserAddressIndex] = useState(
    DeliveryAddressIndexStore.getValue() || undefined
  );
  const [selectedTakeAwayStoreIndex, setSelectedTakeAwayStoreIndex] = useState(
    SelectedTakeAwayStoreIndex.getValue() || undefined
  );
  const options = deliveryServices
    ? deliveryServices?.map?.(i => {
        return {
          subtext: i.deliversToAddress
            ? t('asides.service_address.select_address')
            : t('asides.service_address.select_store'),
          icon: getDeliveryServiceIcon(i.id),
          ...i,
        };
      })
    : [];

  const [addressPickerStatus, setAddressPickerStatus] = useState(InitPickupMethodStore.getValue());
  const [selectedService, setSelectedService] = useState(
    options ? options.find(i => i.id === addressPickerStatus) : []
  );
  const [user, setUser] = useState();

  const getOrFetchDeliveryServices = () => {
    if (DeliveryServicesStore.getValue().length > 0) {
      return DeliveryServicesStore.getValue();
    }
    return updateDeliveryServices();
  };

  const updateDeliveryServices = async () => {
    const config = await getV3DeliveryServicesConfiguration({ enabled: true });
    DeliveryServicesStore.update(config);
    return config;
  };

  useEffect(() => {
    const UserStore$ = User.subscribe(setUser);
    const DeliveryServicesStore$ = DeliveryServicesStore.subscribe(setDeliveryServices);
    const InitPickupMethodStore$ = InitPickupMethodStore.subscribe(setAddressPickerStatus);
    const ShopStore$ = Shop.subscribe(setShop);
    const DeliveryAddressIndexStore$ = DeliveryAddressIndexStore.subscribe(setSelectedUserAddressIndex);
    const SelectedTakeAwayStoreIndex$ = SelectedTakeAwayStoreIndex.subscribe(setSelectedTakeAwayStoreIndex);

    return () => {
      SelectedTakeAwayStoreIndex$.unsubscribe();
      DeliveryAddressIndexStore$.unsubscribe();
      DeliveryServicesStore$.unsubscribe();
      InitPickupMethodStore$.unsubscribe();
      ShopStore$.unsubscribe();
      UserStore$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (options?.length > 0 && addressPickerStatus) {
      setSelectedService(options.find(i => i.id === addressPickerStatus));
    }
  }, [addressPickerStatus, deliveryServices.length]);

  useEffect(() => {
    //Auto select the first service if no selection has been made
    if (deliveryServices?.length > 0 && !addressPickerStatus) {
      InitPickupMethodStore.update(deliveryServices[0].id);
    }
  }, [deliveryServices?.length]);

  useEffect(() => {
    const selectedDeliveryService = deliveryServices?.find(d => d.id === addressPickerStatus);

    // TODO: Write this better
    let timezone;
    if (selectedDeliveryService?.deliversToAddress) {
      timezone =
        user?.addresses?.[selectedUserAddressIndex]?.timeZone ||
        shop?.selectedShop?.address?.timeZone ||
        dayjs.tz.guess();
    } else {
      timezone = shop?.selectedShop?.address?.timeZone || dayjs.tz.guess();
    }

    setDefaultTz(timezone);
  }, [selectedUserAddressIndex, addressPickerStatus, user, deliveryServices, shop]);

  const updateSelectedDeliveryService = serviceId => {
    if (serviceId !== selectedService?.id) {
      //clear any prebooking data
      Prebook.reset();
    }

    setAddressPickerStatus(serviceId || deliveryServices?.[0]?.id);
    InitPickupMethodStore.update(serviceId || deliveryServices?.[0]?.id);
  };

  async function fetchDeliveryMethods(postalCodeInput) {
    const [easyTimeslots, _easyMessage, selectedIndex, selectedDayIndex] = await fetchTimeslots(
      postalCodeInput,
      true,
      selectedService?._id
    );

    GeneralSelectedTimeslotStore.update({ selectedIndex, selectedDayIndex });
  }

  useEffect(() => {
    if (!user || !User.getValue()?.addresses?.length || !deliveryServices.length) return;
    const initialService = selectedService ?? deliveryServices.find(i => i.id === addressPickerStatus);
    if (initialService?.deliversToAddress) {
      if (selectedUserAddressIndex !== undefined) {
        let selectedAddressObj = User.getValue().addresses[selectedUserAddressIndex];
        if (selectedAddressObj) {
          selectedAddressObj = User.getValue().addresses[selectedUserAddressIndex];
        } else {
          selectedAddressObj = User.getValue().addresses[0];
          setSelectedUserAddressIndex(0);
          handleSelectUserAddressIndex(0);
        }
        PostalCode.update(selectedAddressObj?.postalCode);
        setShopId(selectedAddressObj?.postalCode, initialService?.id);
        fetchDeliveryMethods(selectedAddressObj?.postalCode);
        FirstSelectedTimeslotStore.update(true);
        GenericTimeslotsStore.reset();
      } else if (User.getValue().addresses.length > 0) {
        handleSelectUserAddressIndex(0);
        setSelectedUserAddressIndex(0);
        // const selectedAddressObj = User.getValue().addresses[0];
        // PostalCode.update(selectedAddressObj?.postalCode);
        // setShopId(selectedAddressObj?.postalCode, selectedService?.id);
        // fetchDeliveryMethods(selectedAddressObj?.postalCode);
        // FirstSelectedTimeslotStore.update(true);
        // GenericTimeslotsStore.reset();
      } else {
        setShopId('');
      }
    }
    //eslint-disable-next
  }, [selectedUserAddressIndex, deliveryServices.length, user]);

  const selectedShop = shop?.selectedShop;
  const selectedDeliveryShop = shop?.selectedDeliveryShop;

  const getCollectionTypes = () => {
    if (!selectedService) return [];
    if (selectedShop) {
      return selectedShop.collectionTypes;
    } else {
      //if no shop has been selected show the selectedService collectionTypes that are marked as visibleToUser: true
      return selectedService.collectionTypes.filter(i => i.visibleToVisitor).map(i => i.id);
    }
  };

  const setSelectedShop = shopObj => {
    Prebook.reset();
    Shop.setSelectedShop(shopObj);
  };

  const setSelectedDeliveryShop = shopObj => {
    Prebook.reset();
    Shop.setSelectedDeliveryShop(shopObj);
  };

  const setShopsByDeliveryService = (deliveryServiceId, shops) => {
    Shop.setShopByDeliveryId(deliveryServiceId, shops);
  };

  const getShopsByDeliveryService = deliveryServiceId => {
    return Shop.getValue()?.[deliveryServiceId] || [];
  };

  const handleSelectUserAddressIndex = idx => {
    Prebook.reset();
    setSelectedUserAddressIndex(idx);
    DeliveryAddressIndexStore.update(idx);
  };

  const handleTakeAwayStoreIndex = idx => {
    Prebook.reset();
    setSelectedTakeAwayStoreIndex(idx);
    SelectedTakeAwayStoreIndex.update(idx);
  };

  return {
    user,
    options,
    deliveryServices,
    addressPickerStatus,
    selectedService,
    selectedShop,
    selectedDeliveryShop,
    selectedUserAddressIndex,
    updateSelectedDeliveryService,
    getCollectionTypes,
    setSelectedService,
    setSelectedShop,
    setSelectedDeliveryShop,
    setSelectedUserAddressIndex: handleSelectUserAddressIndex,
    setShopsByDeliveryService,
    getShopsByDeliveryService,
    getOrFetchDeliveryServices,
    setSelectedTakeAwayStoreIndex: handleTakeAwayStoreIndex,
    selectedTakeAwayStoreIndex,
  };
}
