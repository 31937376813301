import { wave } from './config';

export async function reserveTimeslot(resource) {
  const response = await wave.post('/v3/timeslots/reservation', {
    ...resource,
  });
  return response.data;
}

export async function releaseTimeslot(requestId, token) {
  const response = await wave.delete(`/v3/timeslots/reservation/request/${requestId}`);
  return response.data;
}
