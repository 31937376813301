import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';

export const SelectedTakeAwayStoreIndex = {
  subject: undefined,

  _lazyInit() {
    if (typeof window !== 'undefined' && window?.localStorage) {
      const storage = window.localStorage;
      const selectedTakeAwayStoreIndex =
        storage && jsonStorage.get('selectedTakeAwayStoreIndex', { storage }) !== null
          ? jsonStorage.get('selectedTakeAwayStoreIndex', { storage })
          : undefined;
      SelectedTakeAwayStoreIndex.subject = new BehaviorSubject(selectedTakeAwayStoreIndex);
      return SelectedTakeAwayStoreIndex.subject;
    }
  },
  update: value => {
    SelectedTakeAwayStoreIndex.subject.next(value);
    const storage = window.localStorage;
    jsonStorage.set('selectedTakeAwayStoreIndex', value, { storage });
  },
  subscribe: setState => {
    const selectedTakeAwayStoreIndex = SelectedTakeAwayStoreIndex._lazyInit();
    return selectedTakeAwayStoreIndex.subscribe(setState);
  },
  getValue: () => {
    const selectedTakeAwayStoreIndex = SelectedTakeAwayStoreIndex._lazyInit();
    return selectedTakeAwayStoreIndex?.value;
  },
};
