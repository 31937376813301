import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import React, { useEffect, useState, useRef, useContext } from 'react';
import cn from 'classnames';
import { SideSheet, DeliveryMethodSelector, LocalShipping, Shop as ShopSvg } from '@desquared/wave-components-library';
import { ServiceAddressSideSheetStore } from '@/store/sideSheet';
import styles from '@/components/Aside/ServiceAddressAside.module.css';
import { toNumber } from 'lodash';
import { useTranslations } from 'next-intl';

//Todo: The backend returns the assets for each delivery service - It would be good to be able to use those and not the hardcoded ones below
export const getDeliveryServiceIcon = serviceId => {
  switch (serviceId) {
    case 'takeAway':
      return ShopSvg;
    case 'standard':
      return LocalShipping;
    default:
      return ShopSvg;
  }
};

export default function ServiceAddressAside(props) {
  const [sideSheet, setSideSheet] = useState(false);
  const { options } = useContext(DeliveryServicesContext);
  const t = useTranslations('common.asides.service_address');

  const refOne = useRef(null);
  const onClick = () => {
    setTimeout(() => {
      ServiceAddressSideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const ServiceAddressSideSheetStore$ = ServiceAddressSideSheetStore.subscribe(setSideSheet);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      ServiceAddressSideSheetStore$.unsubscribe();

      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      ServiceAddressSideSheetStore.close();
    }
  };
  if (!sideSheet || !options) return null;
  return (
    <div ref={refOne}>
      <SideSheet header={t('header')} hasClose={true} onClose={onClick} buttonText={t('save_btn')} onSubmit={onClick}>
        <div className={cn(styles.container)}>
          <DeliveryMethodSelector
            selected={toNumber(props.addressPickerStatus)}
            options={options}
            onClick={props.onClick}
          />
        </div>
      </SideSheet>
    </div>
  );
}
