import { getShopsV3 } from '../api/Shops';

import { findFirstAvailableSlot, updateEasyTimeslotsV3, getOrFetchEasyTimeSlots } from '@/utils/timeslots';
import { PREDICTION_TIMESLOT_TOKEN } from './logic/timeslots';

export async function fetchTimeslots(postalCode, isGet, selectedServiceId) {
  if (!selectedServiceId) return [[], '', -1, -1];

  try {
    const shopsV3 = await getShopsV3(selectedServiceId, postalCode);
    if (!shopsV3.shops[0]?._id) {
      const easyTimeslots = [];
      const easyMessage = 'Δεν εξυπηρετείται';
      return [easyTimeslots, easyMessage, -1, -1];
    }

    const shopId = shopsV3.shops[0]._id;
    const timeslotResponse = isGet
      ? await updateEasyTimeslotsV3(shopId, selectedServiceId, postalCode)
      : await getOrFetchEasyTimeSlots(shopId, selectedServiceId, postalCode);

    const timeslots = timeslotResponse?.timeslots || {};
    const {
      message = '',
      selectedIndex = -1,
      selectedDayIndex = -1,
    } = getTimeslotsMessageIndex(
      timeslots.type === PREDICTION_TIMESLOT_TOKEN ? timeslots.prediction : timeslots.exactTimeTimeslots || []
    );

    return [timeslots, message, selectedIndex, selectedDayIndex];
  } catch (err) {
    // console.error('Error fetching timeslots:', err);
    return [[], '', -1, -1];
  }
  // const shopsV3 = await getShopsV3(selectedServiceId, postalCode);
  // if (!shopsV3.shops[0]?._id) {
  //   const easyTimeslots = [];
  //   const easyMessage = 'Δεν εξυπηρετείται';
  //   return [easyTimeslots, easyMessage, -1, -1];
  // }
  // const { timeslots } = isGet
  //   ? await updateEasyTimeslotsV3(shopsV3.shops[0]._id, selectedServiceId, postalCode)
  //   : await getOrFetchEasyTimeSlots(shopsV3.shops[0]._id, selectedServiceId, postalCode);
  // const easyTimeslots = timeslots;
  // const { message, selectedIndex, selectedDayIndex } = getTimeslotsMessageIndex(
  //   timeslots.type === PREDICTION_TIMESLOT_TOKEN ? timeslots.prediction : timeslots.exactTimeTimeslots
  // );

  // return [easyTimeslots, message, selectedIndex, selectedDayIndex];
}

export function getTimeslotsMessageIndex(timeSlots) {
  const noAvailableTimeSlotsTakeAway = 'Δεν υπάρχουν διαθέσιμες ώρες παράδοσης.';

  if (!timeSlots) return noAvailableTimeSlotsTakeAway;
  const result = findFirstAvailableSlot(timeSlots);
  const selectedIndex = result ? result.index : -1;
  const selectedDayIndex = result ? result.dayIndex : -1;

  if (!result?.timeslot) return { message: noAvailableTimeSlotsTakeAway, selectedIndex, selectedDayIndex };
  const start = result.timeslot.startsAt;
  const end = result.timeslot.endsAt;
  const dateObject = new Date(result.timeslot.startsAtDate);
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
  const day = String(dateObject.getDate()).padStart(2, '0');

  const message = `${day}/${month} ${start} - ${end}`;
  return { message, selectedIndex, selectedDayIndex };
}

export default { fetchTimeslots, getTimeslotsMessageIndex };
