import { SelectedShopId } from '@/store/isAddressRegistered.js';
import ServiceOptions from '@/store/serviceInfo';
import { getTimeslotsMessageIndex, fetchTimeslots } from '@/utils/expressMode';
import { updateTakeAwayTimeslotsV3 } from '@/utils/timeslots';
import { GeneralSelectedTimeslotStore } from '@/store/selectedTimeslot';

export const PREDICTION_TIMESLOT_TOKEN = 'PREDICTION';
export const EXACT_DATE_TIMESLOT_TOKEN = 'EXACT_DATE_TIME';

export async function fetchTimeslotsTakeAway(selectedServiceId) {
  const currentShop = SelectedShopId.getValue();
  ServiceOptions.setValidTakeAway(true);
  const { timeslots } = await updateTakeAwayTimeslotsV3(currentShop, selectedServiceId);

  return timeslots;
}

export function changeSelectedIndexes(localTimeslots, setTakeAwayIndexes, setMessage) {
  const { message, selectedIndex, selectedDayIndex } = getTimeslotsMessageIndex(localTimeslots);
  const { selectedIndex: awayselectedIndex, selectedDayIndex: awayselectedDayIndex } =
    GeneralSelectedTimeslotStore.getValue();
  setTakeAwayIndexes(
    awayselectedIndex &&
      awayselectedIndex !== -1 &&
      awayselectedDayIndex !== -1 &&
      localTimeslots?.length < awayselectedDayIndex &&
      localTimeslots[awayselectedDayIndex].localTimeslots.length < awayselectedIndex &&
      localTimeslots[awayselectedDayIndex]?.localTimeslots[awayselectedIndex]?.isAvailable
      ? { selectedIndex: awayselectedIndex, selectedDayIndex: awayselectedDayIndex }
      : { selectedIndex, selectedDayIndex }
  );
  GeneralSelectedTimeslotStore.update(
    awayselectedIndex &&
      awayselectedIndex !== -1 &&
      awayselectedDayIndex !== -1 &&
      localTimeslots?.length < awayselectedDayIndex &&
      localTimeslots[awayselectedDayIndex].localTimeslots.length < awayselectedIndex &&
      localTimeslots[awayselectedDayIndex].localTimeslots[awayselectedIndex].isAvailable
      ? { selectedIndex: awayselectedIndex, selectedDayIndex: awayselectedDayIndex }
      : { selectedIndex, selectedDayIndex }
  );
  setMessage(message);
}

// export async function fetchTimeslotsDelivery(postalCodeInput, fetchNew, setGenericIndexes, selectedServiceId) {
//   const [easyTimeslots, _easyMessage, selectedIndex, selectedDayIndex] = await fetchTimeslots(
//     postalCodeInput,
//     fetchNew,
//     selectedServiceId
//   );
//   setGenericIndexes({ selectedIndex, selectedDayIndex });
//   GeneralSelectedTimeslotStore.update({ selectedIndex, selectedDayIndex });
//   return easyTimeslots;
// }

export async function fetchTimeslotsDelivery(postalCodeInput, fetchNew, setGenericIndexes, selectedServiceId) {
  try {
    const [easyTimeslots, easyMessage, selectedIndex, selectedDayIndex] = await fetchTimeslots(
      postalCodeInput,
      fetchNew,
      selectedServiceId
    );

    setGenericIndexes({ selectedIndex, selectedDayIndex });
    GeneralSelectedTimeslotStore.update({ selectedIndex, selectedDayIndex });

    return easyTimeslots;
  } catch (err) {
    setGenericIndexes({ selectedIndex: -1, selectedDayIndex: -1 });
    GeneralSelectedTimeslotStore.update({ selectedIndex: -1, selectedDayIndex: -1 });
    return [];
  }
}

export function createPredictiveTimeslotText(prediction, deliveryToken, dayToken) {
  let firstText, secondText;

  if (prediction.predictionType === 'BETWEEN') {
    firstText = deliveryToken || `Παράδoση σε`;
    secondText = `${prediction.between.min} - ${prediction.between.max} ${
      prediction.between.timeUnit === 'DAY' ? dayToken || 'ημέρες' : dayToken || 'μήνες'
    }`;
  }

  // Cover other cases here
  return [firstText, secondText];
}

export function createPredictiveTimeslotTextWithDate(prediction, deliveryToken, locale) {
  let firstText, secondText;
  const dateFormattingOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  if (prediction.predictionType === 'BETWEEN') {
    firstText = deliveryToken || `Παράδoση`;
    const firstDate = new Date(prediction.timeslotStart),
      secondDate = new Date(prediction.timeslotEnd);
    secondText = `${firstDate.toLocaleDateString(locale, dateFormattingOptions)} - ${secondDate.toLocaleDateString(
      locale,
      dateFormattingOptions
    )}`;
  }

  return [firstText, secondText];
}

export default { fetchTimeslotsTakeAway, fetchTimeslotsDelivery };
