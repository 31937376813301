import { createContext } from 'react';
import Prebook from '@/store/prebook.js';
import PrebookEditOrder from '@/store/prebookEditOrder.js';

export const DeliveryServicesContext = createContext({
  servicesV3: [],
  deliveryServices: [],
  shop: undefined,
  selectedService: undefined,
  selectedShop: undefined,
  addressPickerStatus: undefined,
  user: undefined,
  selectedUserAddressIndex: undefined,
  setSelectedUserAddressIndex: () => {},
  getCollectionTypes: () => {},
  setShopsByDeliveryService: () => {},
  getShopsByDeliveryService: () => {},
  getOrFetchDeliveryServices: () => {},
  PrebookStore: Prebook,
  PrebookEditOrderStore: PrebookEditOrder,
  setSelectedTakeAwayStoreIndex: () => {},
  selectedTakeAwayStoreIndex: undefined,
});
