import { getTimeslotsForTakeAwayShop, getTimeslots, getTimeslotsForShopV3 } from '../api/Shops';
import { GenericTimeslotsStore } from '../store/timeslots';

export function getOrFetchTakeAwayTimeSlots(shop) {
  if (GenericTimeslotsStore.isFetched()) {
    return GenericTimeslotsStore.getValue();
  }

  return updateTakeAwayTimeslotsV3(shop);
}

export function getTakeAwayTimeSlots(shop) {
  return updateTakeAwayTimeslotsV3(shop);
}

export async function updateTakeAwayTimeslotsV3(shop, selectedServiceId) {
  const timeslots = await getTimeslotsForShopV3(shop, selectedServiceId);
  GenericTimeslotsStore.update(timeslots);
  return timeslots;
}

export async function updateTakeAwayTimeslots(shop) {
  const timeslots = await getTimeslotsForTakeAwayShop(shop);

  GenericTimeslotsStore.update(timeslots);

  return timeslots;
}

export async function updateEasyTimeslotsV3(shop, selectedServiceId, postalCode) {
  const timeslots = await getTimeslotsForShopV3(shop, selectedServiceId, postalCode);
  GenericTimeslotsStore.update(timeslots);
  return timeslots;
}

export function findFirstAvailableSlot(data) {
  if (!data || data.length === 0) {
    return null;
  }
  for (let j = 0; j < data.length; j++) {
    const entry = data[j];
    for (let i = 0; i < entry.timeslots.length; i++) {
      const timeslot = entry.timeslots[i];
      if (timeslot.isAvailable === true) {
        return { timeslot, index: i, dayIndex: j };
      }
    }
  }
  return null;
}

export function getOrFetchEasyTimeSlots(shop, postalCode) {
  if (GenericTimeslotsStore.isFetched()) {
    return GenericTimeslotsStore.getValue();
  }
  return updateEasyTimeslotsV3(shop, postalCode);
}

export default {
  getOrFetchTakeAwayTimeSlots,
  updateTakeAwayTimeslots,
  updateTakeAwayTimeslotsV3,
  findFirstAvailableSlot,
  getOrFetchEasyTimeSlots,
  updateEasyTimeslotsV3,
};
