import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';

export const FirstSelectedTimeslotStore = {
  subject: undefined,

  _lazyInit() {
    if (FirstSelectedTimeslotStore.subject) return FirstSelectedTimeslotStore.subject;
    const storage = window.localStorage;
    const firstSelectedTimeslot = jsonStorage.get('firstSelectedTimeslot', { storage });
    FirstSelectedTimeslotStore.subject = new BehaviorSubject(firstSelectedTimeslot);
    return FirstSelectedTimeslotStore.subject;
  },
  update: timeslot => {
    const firstSelectedTimeslotStore = FirstSelectedTimeslotStore._lazyInit();
    firstSelectedTimeslotStore.next(timeslot);
    const storage = window.localStorage;
    jsonStorage.set('firstSelectedTimeslot', timeslot, { storage });
  },
  subscribe: setState => {
    const timeslots = FirstSelectedTimeslotStore._lazyInit();
    return timeslots.subscribe(setState);
  },
  getValue: () => {
    const timeslots = FirstSelectedTimeslotStore._lazyInit();
    return timeslots.value;
  },
};

export const GeneralSelectedTimeslotStore = {
  subject: undefined,

  _lazyInit() {
    if (GeneralSelectedTimeslotStore.subject) return GeneralSelectedTimeslotStore.subject;
    // if (window?.localStorage) {
    const storage = window.localStorage;
    const generalSelectedTimeslot = jsonStorage.get('generalSelectedTimeslot', { storage }) || 'none';
    GeneralSelectedTimeslotStore.subject = new BehaviorSubject(generalSelectedTimeslot);
    return GeneralSelectedTimeslotStore.subject;
    // }
  },
  update: timeslot => {
    const generalSelectedTimeslotStore = GeneralSelectedTimeslotStore._lazyInit();
    generalSelectedTimeslotStore.next(timeslot);
    const storage = window.localStorage;
    jsonStorage.set('generalSelectedTimeslot', timeslot, { storage });
  },
  subscribe: setState => {
    const timeslots = GeneralSelectedTimeslotStore._lazyInit();
    timeslots.subscribe(setState);
  },
  getValue: () => {
    const timeslots = GeneralSelectedTimeslotStore._lazyInit();
    return timeslots?.value;
  },
};

export default {
  FirstSelectedTimeslotStore,
  GeneralSelectedTimeslotStore,
};
