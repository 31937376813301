import { BehaviorSubject } from 'rxjs';

const availableServices = new BehaviorSubject([]);
const subjectIsFetched = new BehaviorSubject(false);

const IsFetched = {
  update: fetched => {
    subjectIsFetched.next(fetched);
  },
};

const DeliveryServicesStore = {
  update: services => {
    availableServices.next(services);
  },
  subscribe: setState => availableServices.subscribe(setState),
  getValue: () => availableServices.value,
  isFetched: () => IsFetched.value,
};

export default DeliveryServicesStore;
